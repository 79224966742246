import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/shared/SEO"
import BlogPostsList from "../components/shared/BlogPostsList"

const BlogPosts = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Blog Posts"
        description="Industry News, Case Studies, and Work Portfolios"
        keywords={[
          `marketing`,
          `digital`,
          `email`,
          `SEO`,
          `search engine optimization`,
          `campaigns`,
          `analytics`,
          `databases`,
        ]}
        pagePath="/blogposts"
        schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Organization",
                "name": "Meteor Affinity",
              }
            }`}
      />
      <BlogPostsList title="Latest Insights from our Team" data={data} />
    </Layout>
  )
}
export default BlogPosts

export const query = graphql`
  query BlogPostsPageQuery {
    allContentfulBlogPost(
      limit: 9
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          body {
            body
            childMarkdownRemark {
              plainText
            }
          }
          image {
            fluid(maxWidth: 350, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          tags
          createdAt
        }
      }
    }
  }
`
